import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import "../bootstrap.min.css";
import { Spinner } from "react-bootstrap";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import colourCodes from "../colours";
import DataContext from "../DataContext";

function Calandar(props) {
  const [events, setEvents] = useState([]);
  const [estDiv, setEstDiv] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { data } = useContext(DataContext);

  function sumTotals(data) {
    let totalSum = 0;

    data.forEach((item) => {
      // Convert the Total value to a number and add it to totalSum
      totalSum += parseFloat(item.Total);
    });

    return totalSum;
  }
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(process.env.REACT_APP_API_CALANDAR, {
        params: {
          user: data.email,
          token: data.token,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setEvents(res.data[0]);
        setEstDiv(res.data[1]);
        console.log(res.data[1]);
      });
  }, [props.update]);

  return (
    <div>
      {isLoading ? (
        <Spinner></Spinner>
      ) : (
        <div style={{ display: "flex", margin: 30 }}>
          {" "}
          {/* Parent div with flex display */}
          {/* Calendar Div */}
          <div
            style={{
              flex: 3,
              backgroundColor: " rgba(78, 122, 140, 0.9)",
              padding: 30,
              borderRadius: 15,
              boxShadow: "10px 10px 10px 10px",
              margin: 20,
            }}
          >
            <FullCalendar
              plugins={[dayGridPlugin, bootstrap5Plugin]}
              initialView="dayGridMonth"
              eventContent={(eventInfo) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "5px",
                    }}
                  >
                    <div
                      style={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "50%",
                        backgroundColor: eventInfo.event.backgroundColor,
                        marginRight: "5px",
                        flexShrink: 0,
                      }}
                    />
                    <div
                      style={{
                        whiteSpace: "normal",
                        overflow: "visible",
                        textOverflow: "clip",
                      }}
                    >
                      {eventInfo.event.title}
                    </div>
                  </div>
                );
              }}
              events={events}
              contentHeight={600}
              expandRows={true}
              themeSystem="bootstrap5"
              eventClick={(info) => {
                info.jsEvent.preventDefault();
                if (info.event.url) window.open(info.event.url);
              }}
            />
          </div>
          {/* Bar Chart Div */}
          <div
            style={{
              flex: 1,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              padding: 30,
              borderRadius: 15,
              boxShadow: "10px 10px 10px 10px",
              margin: 20,
            }}
          >
            <h5>Estimated Dividends</h5>
            <ResponsiveContainer width="100%" height={600}>
              <BarChart
                layout="vertical"
                data={estDiv}
                margin={{
                  top: 20,
                  right: 30,
                  left: 10,
                  bottom: 5,
                }}
              >
                <XAxis type="number" />
                <YAxis
                  dataKey="name"
                  type="category"
                  tickFormatter={(tick) =>
                    tick.length > 4 ? tick.substring(4) : tick
                  }
                />
                <Tooltip
                  content={({ active, payload, label }) => {
                    if (active && payload && payload.length) {
                      return (
                        <div
                          style={{
                            backgroundColor: "#fff",
                            padding: "10px",
                            border: "1px solid #ccc",
                          }}
                        >
                          {payload.map((entry, index) => (
                            <p key={`item-${index}`}>{`${
                              entry.name
                            }: £${entry.value.toFixed(2)}`}</p>
                          ))}
                        </div>
                      );
                    }

                    return null;
                  }}
                />

                {props.data.map((n, index) => (
                  <Bar
                    key={n.symbol}
                    dataKey={n.symbol}
                    stackId="a"
                    fill={colourCodes[index]}
                  >
                    {index === props.data.length - 1 && (
                      <LabelList dataKey="Total" position="right" />
                    )}
                  </Bar>
                ))}
              </BarChart>
            </ResponsiveContainer>
            Total: £{sumTotals(estDiv).toFixed(2)}
          </div>
        </div>
      )}
    </div>
  );
}

export default Calandar;
