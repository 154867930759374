import "../App.css";
import StockModal from "../components/addStockModal";
import { useEffect, useState, useContext, version } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import SearchInput from "../components/searchBar";
import axiosThrottle from "axios-request-throttle";
import StockModalInfo from "../components/viewStockModal";
import Toast from "../components/toast";
import "./portfolio.css";
import { Spinner } from "react-bootstrap";
import universalStyle from "../universalStyle";
import StockInfoCard from "../components/StockInfoCard";
import colourCodes from "../colours";
import Icon from "react-crud-icons";
import PortfolioTable from "../components/portfolioTable";
import { useMediaQuery } from "react-responsive";
import DataContext from "../DataContext";
import styled from "styled-components";
import { motion } from "framer-motion";

const Card = styled(motion.div)`
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 30px;
  width: 80%;
  max-width: 1200px;
`;

axiosThrottle.use(axios, { requestsPerSecond: 2 });

function Portfolio(props) {
  const { data } = useContext(DataContext);

  const [show, setShow] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [text, setText] = useState("Add");
  const [stockDataTicker, setStockDataTicker] = useState({ ticker: "ji" });
  const [stockDataCost, setStockDataCost] = useState({ cost: 0 });
  const [stockDataNoOfShares, setStockDataNoOfShares] = useState({
    noOfShares: 0,
  });
  const [sold, setSold] = useState(false);
  const [soldData, setSoldData] = useState([
    {
      noOfSharesSold: 0,
      value: 0,
      date: new Date(),
    },
  ]);
  const [buyData, setBuyData] = useState([
    {
      noOfSharesBought: 0,
      cost: 0,
      date: new Date(),
    },
  ]);
  const [dateAquired, setDateAquired] = useState({ dateAquired: new Date() });
  const [sortCategory, setSortCategory] = useState("none");

  const [ID, setID] = useState({ ID: "" });
  const [update, setUpdate] = useState(false);
  const [startDate, setStartDate] = useState({ 0: new Date() });
  const [rows, setRows] = useState([1]);
  const [buyRows, setBuyRows] = useState([1]);
  const [sellRows, setSellRows] = useState([1]);

  const [divAmount, setDivAmount] = useState({ 0: 0 });
  const [details, setDetails] = useState({
    divDate: [],
    financials: [[], [], []],
  });
  const [message, setMessage] = useState("BP Edited!");
  const [showToast, setShowToast] = useState(false);
  const [disable, setDisable] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [bestPerformers, setBestPerformers] = useState(["test"]);
  const [collapse, setCollapse] = useState(false);
  const [hide, setHide] = useState(false);

  const [sharedData, setSharedData] = useState({
    market: "",
    mostGain: "",
    mostLost: "",
    mostAction: "",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1360px)" });
  const isLaptop = useMediaQuery({ query: "(min-width: 1225)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  function getTotalDividendValue(arr) {
    let total = 0;
    for (let i = 0; i < arr.length; i++) {
      const dividends = arr[i].divDate;
      for (let j = 0; j < dividends.length; j++) {
        total += dividends[j].dividend;
      }
    }
    return total;
  }

  function calculateTotalPrice(data) {
    let totalPrice = 0;
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      totalPrice += item.price * item.noOfShares;
    }

    return totalPrice;
  }

  function calculateSumOfCost(data) {
    let sumOfCost = 0;
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      sumOfCost += item.cost;
    }
    return sumOfCost;
  }

  function addRow() {
    const rows2 = [...rows];
    rows2.push("new row");
    const index = rows2.length - 1;
    setRows(rows2);
    setStartDate(
      (prevState) => ({
        ...prevState,
        [index]: new Date(),
      }),
      setDivAmount((prevState) => ({
        ...prevState,
        [index]: 0,
      }))
    );
  }

  function addSellRow() {
    const rows2 = [...soldData];
    rows2.push({
      noOfSharesSold: 0,
      value: 0,
      date: new Date(),
    });
    setSoldData(rows2);
  }
  function removeSellRow() {
    const rows2 = [...soldData];
    rows2.pop();
    setSoldData(rows2);
  }
  function addBuyRow() {
    const rows2 = [...buyData];
    rows2.push({
      noOfSharesBought: 0,
      cost: 0,
      date: new Date(),
    });
    setBuyData(rows2);
  }
  function removeBuyRow() {
    const rows2 = [...buyData];
    rows2.pop();
    setBuyData(rows2);
  }

  function removeRow() {
    const rows2 = [...rows];
    rows2.pop();
    setRows(rows2);
    const index = rows.length - 1;
    delete startDate[index];
    delete divAmount[index];
  }

  const updateStage = (index, set, newData) => {
    set((prevItems) =>
      prevItems.map((item, i) => (i === index ? newData : item))
    );
    console.log(soldData);
  };

  function combineObjects(obj1, obj2) {
    const combinedArr = [];
    for (let key in obj1) {
      const index = parseInt(key);
      const date = obj2[key];
      const dividend = obj1[key];
      if (dividend > 0) {
        combinedArr.push({ index, date, dividend });
      }
    }
    return combinedArr;
  }

  //DELETE THIS AFTER I REMOVE CONTEXT

  const deleteStock = (id) => {
    axios
      .post(
        process.env.REACT_APP_API_DELETE,
        { ID: id, user: data.email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
        }
      )
      .then((res) => {
        setShowToast(true);
        setMessage(`Deleted`);
        setTimeout(() => setShowToast(false), 3000);
      })
      .catch((err) => {
        console.log(err);
      })
      .then((response) => {
        setShowInfo(false);
      })
      .catch(function (error) {
        console.error(error);
      });

    props.setUpdate(!props.update);
  };

  async function addStock(divDate) {
    await axios
      .post(
        process.env.REACT_APP_API_ADD,
        {
          symbol: stockDataTicker.ticker,
          cost: parseInt(stockDataCost.cost),
          noOfShares: parseInt(stockDataNoOfShares.noOfShares),
          divDate,
          user: data.email,
          sold: sold,
          soldData: soldData,
          buyData: buyData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
        }
      )
      .then((res) => {
        setShowToast(true);
        setMessage(`${res.data.symbol} Added`);
        props.setUpdate(!props.update);
        setDisable(false);

        setTimeout(() => setShowToast(false), 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const editStock = (divDate) => {
    axios
      .post(
        process.env.REACT_APP_API_EDIT,
        [
          { _id: ID.ID },
          {
            symbol: stockDataTicker.ticker,
            cost: stockDataCost.cost,
            noOfShares: stockDataNoOfShares.noOfShares,
            divDate,
            user: data.email,
            sold: sold,
            soldData: soldData,
            buyData: buyData,
          },
        ],
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
        }
      )
      .then((res) => {
        setShowToast(true);
        setMessage(`${res.data.symbol} Edited`);
        setDivAmount({ 0: 0 });
        setStartDate({ 0: 0 });
        props.setUpdate(!props.update);
        setTimeout(() => setShowToast(false), 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAndAdd = () => {
    const divDate = combineObjects(divAmount, startDate);
    setShow(false);
    addStock(divDate);
    setDisable(true);
    setSearchResults([]);
    setSearchQuery("");
  };

  const close = () => {
    setShowInfo(false);
  };
  const open = (details) => {
    console.log(details);
    setShowInfo(true);
    setDetails(details);
  };

  const handleAndEdit = () => {
    const divDate = combineObjects(divAmount, startDate);
    setShow(false);
    editStock(divDate);
  };

  const handleShowAndEdit = (
    ID,
    cost,
    ticker,
    no,
    divDate,
    sold,
    soldData,
    buyData
  ) => {
    setSold(sold);
    setSoldData(soldData);
    setBuyData(buyData);
    setShowInfo(false);
    setShow(true);
    setText("Update");
    setID({ ID: ID });
    divDate !== undefined ? setRows(divDate) : setRows([0]);
    setStockDataCost({ cost: cost });
    setStockDataTicker({ ticker: ticker });
    setStockDataNoOfShares({ noOfShares: no });
    divDate !== undefined
      ? divDate.map((n, index) =>
          setStartDate((prevState) => ({
            ...prevState,
            [index]: new Date(n.date),
          }))
        )
      : setRows([0]);

    divDate !== undefined
      ? divDate.map((n, index) =>
          setDivAmount((prevState) => ({
            ...prevState,
            [index]: n.dividend,
          }))
        )
      : setRows([0]);
  };

  const handleShow = () => {
    setShow(true);
    setText("Add");
    setStockDataCost({ cost: 0 });
    setStockDataNoOfShares({ noOfShares: 0 });
  };

  const handleClose = () => {
    setDivAmount({ 0: 0 });
    setStartDate({ 0: 0 });
    setShow(false);
  };
  const log = (value) => {
    setStockDataTicker({ ticker: value });
  };

  async function findSymbol(ticker) {
    try {
      const response = await axios.request({
        method: "GET",
        url: "https://apidojo-yahoo-finance-v1.p.rapidapi.com/auto-complete",
        params: {
          q: ticker,
          region: "GB",
        },
        headers: {
          "content-type": "application/octet-stream",
          "X-RapidAPI-Key": process.env.REACT_APP_KEY,
          "X-RapidAPI-Host": "apidojo-yahoo-finance-v1.p.rapidapi.com",
        },
      });
      const symbol = response.data;
      return symbol.quotes;
    } catch (error) {
      console.error(error);
    }
  }
  const styles = getStyles(isBigScreen, isTabletOrMobile);

  return (
    <div style={{ ...styles.parent }}>
      {props.isLoading ? <Spinner></Spinner> : null}

      {showToast ? (
        <div style={{ zIndex: "5" }}>
          <Toast message={message} />{" "}
        </div>
      ) : null}
      <StockModal
        addBuyRow={addBuyRow}
        addStock={addStock}
        handleAndAdd={handleAndAdd}
        handleClose={handleClose}
        handleShow={handleShow}
        show={show}
        setShow={setShow}
        handleAndEdit={handleAndEdit}
        text={text}
        setStockDataCost={setStockDataCost}
        setStockDataTicker={setStockDataTicker}
        setStockDataNoOfShares={setStockDataNoOfShares}
        stockDataTicker={stockDataTicker}
        stockDataCost={stockDataCost}
        stockDataNoOfShares={stockDataNoOfShares}
        addRow={addRow}
        setStartDate={setStartDate}
        divAmount={divAmount}
        setDivAmount={setDivAmount}
        startDate={startDate}
        rows={rows}
        removeRow={removeRow}
        open={open}
        setShowInfo={setShowInfo}
        setDateAquired={setDateAquired}
        setSoldData={setSoldData}
        setSold={setSold}
        sold={sold}
        soldData={soldData}
        addSellRow={addSellRow}
        sellRows={sellRows}
        removeSellRow={removeSellRow}
        updateStage={updateStage}
        removeBuyRow={removeBuyRow}
        buyData={buyData}
        setBuyData={setBuyData}
      />

      <StockModalInfo
        handleShow={setShowInfo}
        show={showInfo}
        close={close}
        details={details}
        value={props.value}
        data={props.data}
        handleShowEdit={handleShowAndEdit}
        deleteStock={deleteStock}
      />

      <div style={{ ...styles.biggerBoxContainer }}>
        <div
          style={{
            ...styles.whiteBox,
            ...styles.searchBox,
          }}
        >
          <SearchInput
            style={{ width: 700 }}
            log={log}
            findSymbol={findSymbol}
            stockDataTicker={stockDataTicker}
            setStockDataTicker={setStockDataTicker}
            handleClose={handleClose}
            handleShow={handleShow}
            show={show}
            portData={props.data}
            disable={disable}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            styles={styles}
            setCollapse={setCollapse}
            setHide={setHide}
            hide={hide}
            collapse={collapse}
            sortCategory={sortCategory}
            setSortCategory={setSortCategory}
            version={props.version}
          />
        </div>
        <div
          style={{
            ...styles.whiteBox,
            ...styles.dividendIncome,
          }}
        >
          <h3
            style={{
              ...styles.title,
            }}
          >
            {" "}
            Dividend Income
          </h3>
          <ul style={{ ...styles.listStyle }}>
            {" "}
            <h5 style={{ ...styles.header }}>
              <li style={{ ...styles.listStyle }}>
                Annual:
                <text style={{ ...styles.normalText }}>
                  {" "}
                  {new Intl.NumberFormat("en-UK", {
                    style: "currency",
                    currency: "GBP",
                  }).format(props.div)}{" "}
                </text>
              </li>
              <li style={{ ...styles.listStyle }}>
                Monthly:{" "}
                <text style={{ ...styles.normalText }}>
                  {" "}
                  {new Intl.NumberFormat("en-UK", {
                    style: "currency",
                    currency: "GBP",
                  }).format(props.div / 12)}
                </text>
              </li>
              <li style={{ ...styles.listStyle }}>
                Daily:
                <text style={{ ...styles.normalText }}>
                  {" "}
                  {new Intl.NumberFormat("en-UK", {
                    style: "currency",
                    currency: "GBP",
                  }).format(props.div / 365)}
                </text>
              </li>
              <li style={{ ...styles.listStyle }}>
                Annual Yield:{" "}
                <text style={{ ...styles.normalText }}>
                  {((props.div / calculateSumOfCost(props.data)) * 100).toFixed(
                    2
                  )}
                  %
                </text>
              </li>
            </h5>
          </ul>
        </div>

        <div
          style={{
            ...styles.whiteBox,
            ...styles.biggerBoxContainer,
            ...styles.portfolioSummary,
          }}
        >
          <h3 style={{ ...styles.title }}> Portfolio summary</h3>

          <ul style={{ ...styles.listStyle }}>
            {" "}
            <h5 style={{ ...styles.header }}>
              <li style={{ ...styles.listStyle }}>
                Total Value:
                <text style={{ ...styles.normalText }}>
                  {" "}
                  {new Intl.NumberFormat("en-UK", {
                    style: "currency",
                    currency: "GBP",
                  }).format(props.value)}
                </text>
              </li>
              <li style={{ ...styles.listStyle }}>
                Total Cost:
                <text style={{ ...styles.normalText }}>
                  {" "}
                  {new Intl.NumberFormat("en-UK", {
                    style: "currency",
                    currency: "GBP",
                  }).format(calculateSumOfCost(props.data))}
                </text>
              </li>
              <li style={{ ...styles.listStyle }}>
                Total dividends:
                <text style={{ ...styles.normalText }}>
                  {" "}
                  {new Intl.NumberFormat("en-UK", {
                    style: "currency",
                    currency: "GBP",
                  }).format(getTotalDividendValue(props.data))}
                </text>
              </li>
              <li style={{ ...styles.listStyle }}>
                Return:
                <text
                  style={
                    calculateTotalPrice(props.data) -
                      calculateSumOfCost(props.data) +
                      getTotalDividendValue(props.data) >
                    0
                      ? { ...styles.normalText, color: "green" }
                      : { ...styles.normalText, color: "red" }
                  }
                >
                  {" "}
                  {new Intl.NumberFormat("en-UK", {
                    style: "currency",
                    currency: "GBP",
                  }).format(
                    calculateTotalPrice(props.data) -
                      calculateSumOfCost(props.data) +
                      getTotalDividendValue(props.data)
                  )}
                </text>
              </li>
              <li>
                Yield:{" "}
                <text style={{ ...styles.normalText }}>
                  {(
                    (getTotalDividendValue(props.data) /
                      calculateSumOfCost(props.data)) *
                    100
                  ).toFixed(2)}
                  %
                </text>
              </li>
            </h5>
          </ul>
        </div>
      </div>
      <div
        style={{
          ...styles.stockInfoCard,
        }}
      >
        {" "}
        {!collapse ? (
          hide ? (
            props.data.map((n, index) => (
              <StockInfoCard
                key={index}
                stockData={{
                  _id: props.data[index]._id,
                  imageUrl: props.data[index].image ? (
                    props.data[index].image
                  ) : (
                    <h1>no image</h1>
                  ),
                  colour: colourCodes[index],
                  cost: props.data[index].cost,
                  title: props.data[index].symbol,
                  noOfShares: props.data[index].noOfShares,
                  price: props.data[index].price,
                  divDate: props.data[index].divDate,
                  handleShow: handleShowAndEdit,
                  open: open,
                  deleteStock: deleteStock,
                  n: n,
                }}
              />
            ))
          ) : (
            props.data
              .sort((a, b) => {
                if (sortCategory === "alpha") {
                  return a.symbol.localeCompare(b.symbol); // Sort alphabetically by name
                } else if (sortCategory === "rating") {
                  return b.divYield - a.divYield; // Sort by highest rating (descending)
                } else if (sortCategory === "gain") {
                  return b.cost - a.cost; // Sort by highest gain (descending)
                }
                return 0; // No sorting for 'none'
              })
              .map((n, index) =>
                props.data[index].price * props.data[index].noOfShares !== 0 ? (
                  <StockInfoCard
                    key={index}
                    stockData={{
                      _id: props.data[index]._id,
                      imageUrl: props.data[index].image ? (
                        props.data[index].image
                      ) : (
                        <h1>no image</h1>
                      ),
                      colour: colourCodes[index],
                      cost: props.data[index].cost,
                      title: props.data[index].symbol,
                      noOfShares: props.data[index].noOfShares,
                      price: props.data[index].price,
                      divDate: props.data[index].divDate,
                      handleShow: handleShowAndEdit,
                      open: open,
                      deleteStock: deleteStock,
                      n: n,
                    }}
                  />
                ) : (
                  <></>
                )
              )
          )
        ) : (
          <div></div>
        )}
      </div>

      {props.version == "premium" ? (
        <div style={{ display: "flex", width: "100%", marginBottom: 20 }}>
          <div
            style={{
              ...styles.whiteBox,
              flex: 1,
              backgroundColor: "rgba(78, 122, 140, 0.9)", // Add this line
            }}
          >
            <h1 style={{ ...styles.title }}>Portfolio Summary</h1>
            <text style={{ textAlign: "left", color: "white" }}>
              <ul>
                <li>
                  {" "}
                  Diversification: The portfolio is diversified across four
                  sectors (Energy, Consumer Cyclical, Basic Materials, and
                  Healthcare), reducing sector-specific risk.
                </li>
                <li>
                  {" "}
                  Dividend Income: The portfolio yields an average dividend
                  yield of 5.03%, which is relatively attractive for
                  income-focused investors. However, the high payout ratio of
                  PSN (1.64) suggests it may be paying out more than its
                  earnings, which can be unsustainable.
                </li>
                <li>
                  {" "}
                  Growth Potential: Analyst ratings suggest a cautious outlook,
                  with a majority recommending hold positions. The volatility of
                  PSN (1.357) indicates higher risk, which should be monitored.{" "}
                </li>
                <li>
                  {" "}
                  Financial Health: The financials show steady revenue and net
                  income growth for the companies, though PSN’s high payout
                  ratio and low dividend cover indicate potential sustainability
                  issues.
                </li>
              </ul>
            </text>
          </div>

          <div
            style={{
              ...styles.whiteBox,
              backgroundColor: "rgba(78, 122, 140, 0.9)",

              flex: 1, // Add this line
            }}
          >
            <h1 style={{ ...styles.title }}>Suggestions</h1>
            <text style={{ textAlign: "left", color: "white" }}>
              <ul>
                <li>
                  {" "}
                  Monitor PSN: Due to its high payout ratio and low dividend
                  cover, closely watch PSN for any signs of financial stress.
                </li>
                <li>
                  {" "}
                  Rebalance if Necessary: Depending on the investor's risk
                  tolerance and income needs, consider rebalancing to reduce
                  exposure to high-volatility stocks like PSN.
                </li>
                <li>
                  {" "}
                  Stay Informed: Keep track of earnings reports and analyst
                  updates, especially for stocks with lower buy recommendations
                  and higher sell recommendations (e.g., PSN, GSK).
                </li>
              </ul>
            </text>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

function getStyles(isBigScreen, isTabletOrMobile) {
  if (isBigScreen) {
    return {
      parent: {
        display: "flex",
        flexWrap: "wrap",
      },
      biggerBoxContainer: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
      },
      dividendIncome: {
        textAlign: "center",
        padding: 10,
        display: "flex",
        flex: 1,
        flexDirection: "column",
      },
      portfolioSummary: {
        marginRight: 100,
        textAlign: "center",
        flexDirection: "column",
        padding: 10,
        display: "flex",
        flex: 1,
      },
      stockInfoCard: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        height: "100%",
        width: "100%",
        padding: 15,
        justifyContent: "center",
        zIndex: "0",
      },
      whiteBox: {
        backgroundColor: "rgba(221, 216, 196, 0.9)",

        marginLeft: 20,
        marginRight: 20,
        borderRadius: 15,
        boxShadow: "10px 10px 10px 10px",
      },
      searchBox: {
        marginLeft: 100,
        zIndex: "1",
        padding: 10,
        display: "flex",
        flex: 1,
        justifyContent: "center",
      },
      title: {
        fontFamily: "Helvetica Neue, Arial, sans-serif",
        fontSize: "1.5em",
        fontWeight: 700,
        color: "#333",
        margin: "0.5em 0",
        lineHeight: 1.2,
        letterSpacing: "0.05em",
        textAlign: "center",
        textTransform: "uppercase",
        textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
        borderBottom: "2px solid #e2e2e2",
        paddingBottom: "0.3em",
      },
      listStyle: { listStyle: "none", marginBottom: 10 },
      normalText: { color: "black" },
      header: { color: "black", fontWeight: "bold" },
    };
  } else {
    return {
      parent: {
        display: "flex",
        flexWrap: "wrap",
      },
      biggerBoxContainer: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
      },
      dividendIncome: {
        textAlign: "center",
        padding: 10,
        display: "flex",
        flex: 1,
        flexDirection: "column",
      },
      portfolioSummary: {
        marginRight: 100,
        textAlign: "center",
        flexDirection: "column",
        padding: 10,
        display: "flex",
        flex: 1,
      },
      stockInfoCard: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        height: "100%",
        width: "100%",
        padding: 15,
        justifyContent: "center",
        zIndex: "0",
      },
      whiteBox: {
        backgroundColor: "rgba(221, 216, 196, 0.9)",
        marginLeft: 20,
        marginRight: 20,
        borderRadius: 15,
        boxShadow: "10px 10px 10px 10px",
      },
      searchBox: {
        marginLeft: 100,
        zIndex: "1",
        padding: 10,
        display: "flex",
        flex: 1,
        justifyContent: "center",
      },
      title: {
        paddingLeft: 10,
        marginBottom: 10,
        color: universalStyle.colours.primary,
        textAlign: "center",
        fontSize: "15px",
      },
      listStyle: { listStyle: "none", marginBottom: 10 },
      normalText: { color: universalStyle.colours.tertiary, fontSize: 10 },
      header: { color: universalStyle.colours.secondary, fontSize: "10px" },
    };
  }
}

export default Portfolio;
