import colourCodes from "../colours";

function Legend(props) {
  return (
    <ul
      style={{
        listStyleType: "none",
        marginTop: 20,
        display: "grid",
        gridTemplateColumns: "auto auto", // Two columns with width auto-adjusting to content
        columnGap: "20px", // Space between the two columns
        padding: 0,
        justifyContent: "center", // Centers the grid within the container
      }}
    >
      {props.name.map((x, i) => {
        // Splitting the name into words
        const words = x.name.split(" ");

        return (
          <li
            key={i}
            style={{
              display: "flex",
              alignItems: "center",
              margin: "5px 0", // Vertical spacing between items
            }}
          >
            <div
              style={{
                marginRight: 5,
                display: "flex",
                textAlign: "start",
              }}
            >
              <span style={{ color: colourCodes[i], fontSize: "1vw" }}>
                {words.join(" ")}
              </span>
            </div>
            <div
              style={{
                width: "1vw", // Responsive size for the small colored box
                height: "1vw", // Responsive size
                backgroundColor: colourCodes[i],
                marginLeft: 5,
              }}
            ></div>
          </li>
        );
      })}
    </ul>
  );
}

export default Legend;
