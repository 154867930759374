import axios from "axios";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import ImportModalCSV from "./ImportModalCSV";
import Icon from "react-crud-icons";

function SearchInput(props) {
  const [focus, setFocus] = useState(false);
  const [loading, isLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleSearch = async () => {
    const data = [];
    isLoading(true);
    await axios
      .request({
        method: "GET",
        url: `https://financialmodelingprep.com/api/v3/search-ticker?query=${props.searchQuery}&limit=10&exchange=LSE&apikey=6NnXQiKotJ8a06rfMG4LRgnSbRWmmnAc`,
      })
      .then((res) => {
        isLoading(false);
        setFocus(true);
        const result = res.data;
        result.forEach((r) => {
          data.push({
            value: r.symbol,
            text: r.name,
          });
        });
        data.length == 0
          ? props.setSearchResults(["Empty"])
          : props.setSearchResults(data);
      })
      .catch((err) => {
        console.error("Error fetching search results:", err);
        props.setSearchResults([{ value: "", text: "No results found" }]);
      });
  };

  const sortOptions = ["none", "alpha", "rating", "gain"];

  const cycleSortCategory = () => {
    const currentIndex = sortOptions.indexOf(props.sortCategory);
    const nextIndex = (currentIndex + 1) % sortOptions.length; // Loop back to 'none'
    props.setSortCategory(sortOptions[nextIndex]);
  };

  return (
    <>
      <div>
        <h3
          style={{
            ...props.styles.title,
          }}
        >
          {" "}
          Add stocks
        </h3>
        <div style={{ display: "flex" }}>
          <input
            onFocus={() => setFocus(true)}
            placeholder={
              props.portData.length === 30
                ? "Only 30 stocks allowed!"
                : "E.g British Petroleum"
            }
            style={{ margin: 20, borderColor: "ActiveBorder" }}
            type="text"
            value={props.searchQuery}
            onChange={(e) => props.setSearchQuery(e.target.value)}
            onBlur={(e) => {
              setTimeout(() => {
                setFocus(false);
              }, 250);
            }}
            disabled={
              props.portData.length === 30 || props.disable ? true : false
            }
          />
          {loading && <Spinner />}
          <Button style={{ height: 60, marginTop: 10 }} onClick={handleSearch}>
            Search
          </Button>
        </div>
        <div>
          <Button
            disabled={props.version == "free"}
            variant="outline-primary"
            onClick={() => setShow(true)}
          >
            Import Portfolio
          </Button>

          {props.collapse == false ? (
            <Icon
              name="collapse"
              tooltip="collapse"
              theme="light"
              size="medium"
              onClick={() => props.setCollapse(true)}
              disabled={props.version == "free"}
            />
          ) : (
            <Icon
              name="expand"
              tooltip="expand"
              theme="light"
              size="medium"
              onClick={() => props.setCollapse(false)}
              disabled={props.version == "free"}
            />
          )}
          {props.hide == false ? (
            <Icon
              name="hide"
              tooltip="hide"
              theme="light"
              size="medium"
              onClick={() => props.setHide(true)}
            />
          ) : (
            <Icon
              name="show"
              tooltip="show"
              theme="light"
              size="medium"
              onClick={() => props.setHide(false)}
            />
          )}
          <Icon
            name="sort"
            tooltip="sort"
            theme="light"
            size="medium"
            onClick={() => cycleSortCategory()}
          />
        </div>
        <ImportModalCSV show={show} setShow={setShow} />
      </div>

      {focus ? (
        <div>
          <ul
            style={{
              listStyle: "none",
              position: "absolute",
              padding: 0, // Remove default padding
            }}
          >
            {props.searchResults.map((result, index) => (
              <li
                onClick={() => props.handleShow()}
                style={{
                  padding: "8px",
                  cursor: "pointer",
                  borderStyle: "solid",
                  transition: "background-color 0.3s ease",
                  backgroundColor: "white",
                  marginBottom: "1px", // Add margin between list items
                }}
                key={index}
                onMouseEnter={(e) => {
                  props.setStockDataTicker({ ticker: result.value });

                  e.target.style.backgroundColor = "lightgray";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "white";
                }}
              >
                {result.text}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default SearchInput;
